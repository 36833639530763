<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
		>
			<el-table-column prop="id" label="ID" width="80px"> </el-table-column>
			<el-table-column prop="title" label="文章标题"> </el-table-column>
			<el-table-column prop="category_name" label="分类" width="80px">
			</el-table-column>
			<el-table-column prop="release_time" label="提交时间" width="80px">
			</el-table-column>

			<el-table-column label="审核时间" width="80px">
				<template slot-scope="scope">
					<span v-if="scope.row.examine_type == 2"></span>
					<span v-else>{{ scope.row.examine_time }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="操作" width="100px"
				><template slot-scope="scope">
					<span class="pointer margin_left_10" @click="showPreView(scope.row)"
						>预览</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)">
						删除
					</span>
				</template>
			</el-table-column>
			<el-table-column label="审核结果描述" width="120px">
				<template slot-scope="scope">
					<span v-if="scope.row.examine_type == 1">审核通过</span>
					<span v-if="scope.row.examine_type == 2">审核中</span>
					<span v-if="scope.row.examine_type == 3">{{
						scope.row.examine_desc != null && scope.row.examine_desc.length > 0
							? scope.row.examine_desc
							: "审核拒绝"
					}}</span>
					<span v-if="scope.row.examine_type == 4">草稿</span>
				</template>
			</el-table-column>

			<el-table-column fixed="right" label="审核" min-width="50">
				<template slot-scope="scope">
					<span class="pointer" @click="pass(scope.row)">通过</span>
					<span class="pointer margin_left_10" @click="notPass(scope.row, 1)">
						版权原因不通过
					</span>
					<span class="pointer margin_left_10" @click="notPass(scope.row, 2)">
						PR原因不通過
					</span>
					<span class="pointer margin_left_10" @click="notPass(scope.row, 3)">
						政策原因不通过
					</span>
					<span class="pointer margin_left_10" @click="notPass(scope.row, 4)">
						其他原因不通过
					</span>
					<span class="pointer margin_left_10" @click="toDetail(scope.row)">
						详情
					</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { addInformation, delInformation } from "@/api/article";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			examineOptionList: [
				{ name: "审核通过", id: 1 },
				{ name: "审核中", id: 2 },
				{ name: "版权问题审核拒绝", id: 3 },
				{ name: "广告问题审核拒绝", id: 4 },
				{ name: "政策问题审核拒绝", id: 5 },
				{ name: "其他问题审核拒绝", id: 6 },
			],
			value: true,
		};
	},
	methods: {
		toDetail(row) {
			this.$router.push({
				path: "/message/addmessage",
				query: { id: row.id, type: "detail" },
			});
		},
		showPreView(row) {
			let url = "https://metaera.hk/contents/" + row.id;
			window.open(url, "预览");
		},
		pass(row) {
			this.examine(1, row, "");
		},
		notPass(row, type) {
			// 1. 文章可能侵犯他人版权或不符合行业伦理标准，不予收录
			// 2. 广告性质过强，内容涉及推广或营销部分过多，不适合收录
			// 3. 涉及国内或港澳台地区时政相关，不予收录
			// 4. 其他原因，具体可咨询人工
			switch (type) {
				case 1:
					this.examine(
						3,
						row,
						"文章可能侵犯他人版权或不符合行业伦理标准，不予收录"
					);
					break;
				case 2:
					this.examine(
						3,
						row,
						"广告性质过强，内容涉及推广或营销部分过多，不适合收录"
					);
					break;
				case 3:
					this.examine(3, row, "涉及国内或港澳台地区时政相关，不予收录");
					break;
				case 4:
					this.examine(3, row, "其他原因，具体可咨询人工");
					break;
				default:
					this.examine(3, row, "其他原因，具体可咨询人工");
					break;
			}
		},

		toDelete(row) {
			this.$confirm("此操作将删除该条资讯, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					delInformation({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		examine(examine_type, row, examine_desc) {
			let that = this;
			let formData = {
				examine_type: examine_type,
				id: row.id,
				examine_desc: examine_desc,
				type: 5,
				score: 1,
				release_time: row.release_time,
				content: row.content,
				source_id: "1024",
			};
			addInformation(formData)
				.then((res) => {
					if (res.code == 200) {
						location.reload();
					} else {
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped></style>
